<template>
  <div class="img-container">
    <div class="top">
      <div @click="$router.back()">
        <i class="el-icon-arrow-left" style="font-size: 20px"></i
        ><span>返回</span>
      </div>
      <span>{{ titleName }}</span>
      <p style="width: 50px"></p>
    </div>
    <div class="search">
      <el-select
        v-model="SearchType"
        slot="prepend"
        style="width: 100px"
        placeholder="请选择"
      >
        <el-option label="文件名" value="1"></el-option>
        <el-option label="文字内容（模糊）" value="3"></el-option>
        <el-option label="文字内容（精确）" value="4"></el-option>
      </el-select>
      <el-input
        style="width: 200px"
        v-model="SearchValue"
        placeholder="请输入中文题名"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="onSearch"
        ></el-button
      ></el-input>
    </div>
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          offset="300"
          v-if="total"
          ref="listRef"
        >
          <van-grid :column-num="2">
            <van-grid-item
              v-for="(item, index) in list"
              :key="item.id"
              :class="{ active: startPosition === index }"
            >
              <img
                style="width: 170px; height: 128px"
                v-lazy="{
                  src: `/api/fileinfo/image/GetOriImageThumbnail?filename=${item.pictureFullName}`,
                  loading: '/loading.gif',
                  error: '',
                }"
                @click="imgClick(index)"
              />
              <p class="text">{{ item.pictureFileName.split(".")[0] }}</p>
              <van-button
                style="height: 28px"
                plain
                type="default"
                @click="getContent(item.id)"
                >生成段落</van-button
              >
            </van-grid-item>
          </van-grid>
        </van-list>
        <van-empty v-else description="暂无数据" />
      </van-pull-refresh>
    </div>
    <van-image-preview
      v-model="mobileImgPreview"
      :images="imagesList"
      :startPosition="startPosition"
      @change="onChange"
      @close="onClose"
      :loop="false"
    >
    </van-image-preview>
    <van-action-sheet
      z-index="9"
      v-model="contentShow"
      title="段落"
      lock-scroll
      overlay
      safe-area-inset-bottom
      :round="false"
      class="action-sheet"
    >
      <div class="operation">
        <div>书写顺序：</div>
        <el-radio @input="onRadioChange" v-model="radio" label="1"
          >横排</el-radio
        >
        <el-radio @input="onRadioChange" v-model="radio" label="2"
          >竖排</el-radio
        >
        <van-button type="default" size="small" @click="doCopy"
          >一键复制</van-button
        >
      </div>
      <div class="box" ref="box">
        <div :class="[radio === '1' ? 'test2' : 'test1']">
          <span id="articleDetailsRight" v-html="content"> </span>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getThumbnail, getInfo, getName } from "@/api/literature.js";
export default {
  data() {
    return {
      citation: "",
      radio: "2",
      mobileImgPreview: false,
      refreshing: false,
      loading: false,
      finished: false,
      titleName: "",
      SearchType: "3",
      SearchValue: "",
      sourceID: "",
      pageIndex: 1,
      pageSize: 10,
      list: [],
      total: 0,
      imagesList: [],
      startPosition: 0,
      content: "",
      contentShow: false,
    };
  },
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  created() {
    this.SearchValue = this.$route.query.searchTitle || "";
    this.sourceID = this.$route.query?.sourceID;
    this.getName(this.sourceID);
    this.getThumbnail();
  },
  methods: {
    doCopy(val) {
      this.$copyText(this.content + "\n" + this.citation).then(
        (res) => {
          this.$message.success("复制成功");
        },
        (e) => {
          this.$message.error("复制失败");
        }
      );
    },
    onClose() {
      let index = Math.ceil(this.startPosition % 2);
      this.$refs.listRef.$el.scrollTop = 185 * index;
    },
    onChange(e) {
      if (e === this.list.length - 2 && e < this.total - 2) {
        this.onLoad();
      }
      this.startPosition = e;
    },
    onRadioChange(e) {
      if (this.content) {
        this.content = this.content
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』")
          .replace(/﹁/g, "「")
          .replace(/﹂/g, " 」");
        // .replace(/︹/g, " ")
        // .replace(/︺/g, " ");
      }
      this.radio = e;
    },
    async getContent(id) {
      try {
        const res = await getInfo({ id, keyWord: this.SearchValue });
        this.content = res.content;
        this.contentShow = true;
      } catch (error) {
        console.log(error);
      }
    },
    onSearch() {
      this.list = [];
      this.pageIndex = 1;
      this.getThumbnail();
    },
    imgClick(index) {
      this.startPosition = index;
      this.mobileImgPreview = true;
    },
    async getName(sourceID) {
      const res = await getName({
        sourceID,
      });
      this.titleName = res.title;
      this.citation = this.generateCitation(res, res.documentType);
    },
    generateCitation(res, type) {
      function formatPart(value, suffix = "") {
        return value ? `${value}${suffix}` : "";
      }

      let citation = "[序号]";

      switch (type) {
        case 134: // 古籍
        case 131: // 方志
        case 100: // 图书
          citation += formatPart(res.contributor, "."); // 如果res.contributor存在，则加上'.'
          citation += formatPart(res.title) + "[M].";
          citation += formatPart(res.placeOfPublication, "."); // 如果res.placeOfPublication存在，则加上'.'
          citation += formatPart(res.publisher, "."); // 如果res.publisher存在，则加上'.'
          citation += formatPart(res.dateOfPublication, ":"); // 如果res.dateOfPublication存在，则加上':'
          citation += formatPart(res.extent, ";"); // 如果res.extent存在，则加上';'
          break;

        case 133: // 谱牒
          citation += formatPart(res.contributor, "."); // 如果res.contributor存在，则加上'.'
          citation += formatPart(res.title) + "[M].";
          citation += formatPart(res.surname, "."); // 如果res.surname存在，则加上'.'
          citation += formatPart(res.hall, "."); // 如果res.hall存在，则加上'.'
          citation += formatPart(res.placeOfPublication, "."); // 如果res.placeOfPublication存在，则加上'.'
          citation += formatPart(res.publisher, "."); // 如果res.publisher存在，则加上'.'
          citation += formatPart(res.dateOfPublication, ":"); // 如果res.dateOfPublication存在，则加上':'
          citation += formatPart(res.extent, ";"); // 如果res.extent存在，则加上';'
          break;

        case 132: // 历史档案
          citation += formatPart(res.contributor, "."); // 如果res.contributor存在，则加上'.'
          citation += formatPart(res.title) + "[M].";
          citation += formatPart(res.fileNumber, "."); // 如果res.fileNumber存在，则加上'.'
          citation += formatPart(res.placeOfPublication, "."); // 如果res.placeOfPublication存在，则加上'.'
          citation += formatPart(res.publisher, "."); // 如果res.publisher存在，则加上'.'
          citation += formatPart(res.dateOfPublication, ":"); // 如果res.dateOfPublication存在，则加上':'
          citation += formatPart(res.extent, ";"); // 如果res.extent存在，则加上';'
          break;

        default:
          citation = "";
          break;
      }

      return citation;
    },
    onLoad() {
      this.pageIndex++;
      this.getThumbnail();
    },
    onRefresh() {
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pageIndex = 1;
      this.getThumbnail();
    },
    async getThumbnail() {
      try {
        this.loading = true;
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }
        const res = await getThumbnail({
          SourceID: this.sourceID,
          PageIndex: this.pageIndex,
          PageSize: this.pageSize,
          SearchType: this.SearchType,
          SearchValue: this.SearchValue,
        });
        this.list = [...this.list, ...res.items];
        this.imagesList = [
          ...this.imagesList,
          ...res.items.map(
            (v) =>
              `/api/fileinfo/image/GetOriImage?filename=${v.pictureFullName}&ocrSource=1`
          ),
        ];
        this.total = res.totalCount;

        if (this.total == 0) return this.$message.info("暂无数据");
        if (this.total <= this.list.length) {
          this.finished = true;
        }
        //   if (this.total) {
        //     this.activeId = res.items[0].id;
        //     this.imgSrc = res.items[0].pictureFullName;
        //     this.getInfo(res.items[0].id);
        //   }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  padding-top: 100px;
  position: relative;

  .top {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 5px;
    border-bottom: 1px solid #000;
    background: #fff;
    z-index: 9;

    span {
      font-size: 18px;
    }
  }

  .search {
    position: fixed;
    top: 50px;
    width: 100%;
    left: 0;
    height: 50px;
    padding: 10px 0;
    z-index: 9;
    background: #fff;
  }

  .content {
    .text {
      width: 170px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      padding: 5px;
      color: #3f3f3f;
    }

    .active {
      box-sizing: border-box;
      width: 100%;
      border: 1px dashed rgb(79, 129, 189);
    }
    ::v-deep .van-grid-item__content {
      padding: 16px 7px;
    }
  }

  .action-sheet {
    .van-action-sheet__content {
      padding-top: 40px;
      height: 450px;
    }
    .operation {
      position: absolute;
      top: 48px;
      background: #fff;
      width: 100%;
      height: 40px;
      font-size: 18px;
      color: #000;
      display: flex;
      border-bottom: 1px solid #ccc;
      align-items: center;

      .el-radio__label {
        font-size: 18px;
        color: #000;
      }

      .el-radio__inner {
        width: 18px;
        height: 18px;
      }
    }

    .box {
      background: #fff;
      height: 410px;
      width: 100%;
      overflow: auto;

      .test1 {
        width: 100%;
        height: 100%;
        overflow: auto;
        font-size: 20px;
        color: #000;
        writing-mode: vertical-lr;
        writing-mode: tb-rl;
        font-family: 宋体;
        white-space: pre-wrap;
        line-height: 22px;
        letter-spacing: 2px;
      }
      .test1:focus {
        transition: border linear 0.1s, box-shadow linear 0.2s;
        box-shadow: 0 0 10px #66afe9; //向外漫射程度1
        -moz-box-shadow: 0 0 10px #66afe9; //向外漫射程度2
        -webkit-box-shadow: 0 0 10px #66afe9; //向外漫射程度3
        border: 1px solid #66afe9;
        outline: 0;
        padding: 9px;
      }

      .test2 {
        width: 100%;
        height: 100%;
        overflow: auto;
        font-size: 20px;
        color: #000;
        // writing-mode: vertical-lr;
        // writing-mode: tb-rl;
        font-family: 宋体;
        white-space: pre-wrap;
        line-height: 22px;
        letter-spacing: 2px;
      }
    }
  }
}
</style>